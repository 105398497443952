import { useState, useCallback, useMemo, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal, Form, Col, Row, Container } from "react-bootstrap";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import APIServicenew from "../utils/APIGeneralService";
import { formateDate, getCookies, setCookies } from "../utils/Helper";
import DynamicDataTableWithCount from "../components/dataTableWithCount";
import { useToasts } from "react-toast-notifications";
import { UserContext } from "../context/UserContextProvider";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";


const CallOrders = () => {
  document.title = "Chat List";
  const navigate = useNavigate();
  const token = getCookies("accessTokenleads");
  const headers = { Authorization: `Bearer ${token}` };
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [chatNumber, setChatNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [userMobNum, setUserMobNum] = useState("");
  const [astroName, setAstroName] = useState("");
  const [astroMobNum, setAstroMobNum] = useState("");
  const [panditName, setPanditName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [allDatapaidCount, setAllDataPaidCount] = useState();
  const [flag, setFlag] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [userForNote, setUserForNote] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [note, setNote] = useState("");
  const [loader, setLoader] = useState(false);
  const APIServices = new APIServicenew(process.env.REACT_APP_REST_API_URL);
  const APIServicesSec = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const { user: adminUser } = useContext(UserContext);
  const { refreshToken } = useContext(UserContext);

  const { addToast } = useToasts();

  // for user calling modal
  const [dial, setDail] = useState("Call");
  const [showCallCm, setShowCallCm] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [ singleData,setSingleData ] = useState("")
  const [ showSingleDataDetail,setShowSingleDataDetail ] = useState(false)

  const handleCloseCallCm = () => {
    setShowCallCm(false);
  };
  const APIServiceschatbot = new APIServicenew(
    process.env.REACT_APP_OTHER_API_URL
  );

  // dropdown states
  const [countData,setCountData]=useState()
  const [orderStatus, setOrderStatus] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [callDuration, setCallDuration] = useState("");
  const [source, setSource] = useState("");

  const userCall = async () => {
    const res = await APIServiceschatbot.post(
      "responders/call",
      {
        userId: currentUserId,
        source: "chat",
      },
      headers
    );

    if (res && res.status === 200) {
      setDail(res?.data?.message);
      setShowCallCm(false);
      addToast("Calling....", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        setDail("Call");
      }, 10000);
    }
  };
  const handleConfirm = () => {
    userCall();
  };

  // for astrologer calling modal

  const [dial1, setDail1] = useState("Call");
  const [showCallCm1, setShowCallCm1] = useState(false);
  const [astroId, setAstroId] = useState("");
  const handleCloseCallCm1 = () => {
    setShowCallCm1(false);
  };

  // date filter

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  
   const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  // const [inputState, setInputState] = useState({
  //   startdate: "",
  // });

  // const [inputStateP, setInputStateP] = useState({
  //   endDate: "",
  // });


  // const handleStartDate = (date) => {
  //   const formattedDate = moment(date).format("MM-DD-YYYY");
  //   setInputState((prevState) => ({ ...prevState, startdate: formattedDate }));
  // };

  // const handleEndDate = (date) => {
  //   const formattedDate = moment(date).format("MM-DD-YYYY");
  //   setInputStateP((prevState) => ({ ...prevState, endDate: formattedDate }));
  // };

  const statusMap = {
    4: { text: "Completed", testClass: "text-success fw-bold" },
    2: { text: "Accepted", testClass: "text-success fw-bold" },
    3: { text: "In-Progress", testClass: "text-pending fw-bold" },
    1: { text: "Requested", testClass: "text-pending fw-bold" },
    5: { text: "Failed", testClass: "text-danger fw-bold" },
    7: { text: "Rejected", testClass: "text-danger fw-bold" },
    6: { text: "Cancelled", testClass: "text-danger fw-bold" },
  };

  const handleUserNotes = async (id) => {
    const res = await APIServicesSec.get(
      `userNotes/getDetailById/${id}`,
      headers
    );

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();

        setNotesData(res.data.data);
        setShow(true);
      } else {
        console.log("error");
      }
    }
  };

  const handleNotesAdd = async () => {
    if (!note || note === "") {
      setError(true);
    } else {
      let payload = {
        userId: userForNote?.userId[0]?._id,
        source:"Leads-Chat-Orders",
        name:userForNote?.chatNumber,
        type: "respnder",
        adminName: adminUser?.name,
        adminId: adminUser?._id,
        message: note,
      };
      setLoader(true);
      const res = await APIServicesSec.post(
        `userNotes/userNoteMessage`,
        payload,
        headers
      );

      if (res && res?.data?.status === true) {
        addToast("Note added successfully", {
          appearance: "success",
          autoDismiss: true,
        });

        setShow(false);
        // getUserNotes(userForNote?.userId)
        setNote("");
        setLoader(false);
      } else {
        // toast.error(res?.data?.message);
        addToast(res?.data?.message, {
          appearance: "denger",
          autoDismiss: true,
        });

        setLoader(false);
      }
      setLoader(false);
    }
  };
  const handleChange = () => {
    setError(false);
  };
  const handleCloseModal = () => {
    setShow(false);
    setError(false);
    setNote("");
  };
  const astrologerCall = async () => {
    const res = await APIServiceschatbot.post(
      "responders/callAstrologer",
      {
        astrologerId: astroId,
        source: "chat",
      },
      headers
    );
    if (res && res.status === 200) {
      setDail1(res?.data?.message);
      setShowCallCm1(false);
      addToast("Astrologer Calling....", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        setDail1("Call");
      }, 10000);
    }
  };

  const handleConfirm1 = () => {
    astrologerCall();
  };

  const resetData = () => {
    setStartDate("");
    setEndDate("")
    setSelectedRange("")
    setChatNumber("");
    setUserName("");
    setUserMobNum("");
    setAstroName("");
    setAstroMobNum("");
    setOrderStatus("");
    setCallStatus("");
    setCallDuration("");
    setSource("");
    setFlag(true);
    setPageIndex(0)
  };

  const columns = useMemo(() => [
    {
      Header: "NO.",
      Cell: (e) => (
        <span>
          {e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}
        </span>
      ),
    },
    {
      Header: "Chat Number",
      Cell: (e) => {
        return <span>{e.row.original.chatNumber ?? "_"}</span>;
      },
    },
    {
      Header: "Astrologer	",
      Cell: (e) => {
        return (
          <>
            <span>
              {e.row.original.astrologerId[0]?.name}/
              {e.row.original.astrologerId[0]?.contactNumber ?? "_"}
            </span>  <br/>
            <button
              onClick={() => {
                setShowCallCm1(true);
                setAstroId(e.row.original.astrologerId[0]?._id);
              }}
              className="mt-2  call_btn btn btn-secondary"
            >
            
              Call to Astrologer
            </button>
          </>
        );
      },
    },
    {
      Header: "User Name/Number	",
      Cell: (e) => {
        return (
          <>
            <span>
              {" "}
              {e.row.original.userId[0]?.name}
              {((e.row.original.userId[0]?.name)&&(e.row.original.userId[0]?.contactNumber))? "/": ""}
              {e.row.original.userId[0]?.contactNumber ?? "_"}
            </span>
            <br/>
        
            <button
              onClick={() => {
                setShowCallCm(true);
                setCurrentUserId(e.row.original.userId[0]?._id);
              }}
              className="mt-2  call_btn btn btn-secondary mt-1"
            >
              Call to User
            </button>
          </>
        );
      },
    },
    {
      Header: "Total Amount",
      Cell: (e) => {
        return <span>₹{e.row.original.totalAmount ?? "_"}.00</span>;
      },
    },
    {
      Header: "Duration",
      Cell: (e) => {
        return <span>{e.row.original.duration ?? "_"}min</span>;
      },
    },
    {
      Header: "Price Per Minute	",
      Cell: (e) => {
        return (
          <span>₹{e.row.original.astrologerChatPricePerMinute ?? "_"}.00</span>
        );
      },
    },
    {
      Header: "IsFree",
      Cell: (e) => {
        return (
          <span>{e.row.original.isFree == false ? "NO" : "Yes" ?? "_"}</span>
        );
      },
    },
    {
      Header: "Source",
      Cell: (e) => {
        return <span>{e.row.original.utmSource ?? "_"}</span>;
      },
    },
    {
      Header: "Device",
      Cell: (e) => {
        return <span>{e.row.original.deviceType ?? "_"}</span>;
      },
    },
    {
      Header: "Chat Status	",
      Cell: (e) => {
        return (
          <span className={statusMap[e.row.original.chatStatus]?.testClass}>
            {statusMap[e.row.original.chatStatus]?.text}
          </span>
        );
      },
    },
    {
      Header: "Created Date	",
      Cell: (e) => {
        return (
          <span>
            {moment(e.row.original.createdAt).format("D MMM, YYYY h:mm A")}
          </span>
        );
      },
    },
    {
      Header: "Notes",
      Cell: (e) => {
        return (
          <>
            <h5 className="mb-0">
              <input
                type="button"
                className="Et-btn notes-btn"
                value="Notes"
                onClick={() => {
                  setUserForNote(e.row.original);
                  handleUserNotes(e.row.original.userId[0]?._id);
                }}
              />
            </h5>
            <Button
                    className="detail_btn"
                    onClick={() => {
                      setSingleData(e.row.original);
                      setShowSingleDataDetail(true)
                    }}
                  >
                    View Details
                  </Button>
          </>
        );
      },
    },
  ]);


  const handleCountData = async (id) => {
    const res = await APIServices.get(`lead/getChatOrderCount`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        setCountData(res.data.resData);
      } else {
        console.log("error");
      }
    }
  };

  useEffect(() => {
    getAllData(skip, limit, pageIndex);
    handleCountData()

  }, [flag]);
  useEffect(()=>{

  },[])

  const handleSearch = () => {
    if (
      chatNumber ||
      userName ||
      userMobNum ||
      astroName ||
      astroMobNum ||
      orderStatus ||
      callStatus ||
      callDuration ||
      source || startDate || endDate
    ) {
      getAllData(
        skip,
        limit,
        0,
        chatNumber,
        userName,
        userMobNum,
        astroName,
        astroMobNum,
        orderStatus,
        callStatus,
        callDuration,
        source,startDate , endDate
      );
    }
  };

  useEffect(()=>{
    authorizeResponder()
  },[])
  
  const authorizeResponder =async()=>{
    const res = await APIServiceschatbot.post(
      "responders/authorize_responder",
        {
        },
        headers
      );
      if (res.data?.status == 4) {
        logout();
      }
    }

  const logout = () => {
    refreshToken(null);
    setCookies("accessTokenleads", null);
    navigate("/");
  };


  const getAllData = useCallback(
    async (skip, limit, index) => {
      // const startDateNew = (startDate === null || startDate === undefined || startDate.startsWith("Invalid")) ? "" : startDate
      try {
        // let page =skip/limit+1
        const response = await APIServices.get(
          `lead/chatOrderList/${skip}/${limit}/?userName=${userName}&astroName=${astroName}&chatStatus=${orderStatus=="all"?"":orderStatus}&callType=&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}&userMobileNumber=${userMobNum}&chatFreePaid=${callStatus=="all"?"":callStatus}&astrologerId=&duration=${callDuration=="all"?"":callDuration}&utmSource=${source=="all"?"":source}&chatNumber=${chatNumber}&astrologerMobileNumber=${astroMobNum}`,
          headers
        );
        // https://astro-api.techopium.com/admin/order/orderList/directCall/0/10/?userName=&astroName=&orderStatus=&callType=&startDate=&endDate=&userMobileNumber=&callFreePaid=&astrologerId=&duration=&isRewardCoinUsed=&type=&panditName=&countryId=&utmSource=&callNumber=&astrologerMobileNumber=
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.total_count / limit));
          setAllDataCount(response.data?.total_count);
          setAllDataPaidCount(response.data?.paidcounts)
          setTableData(response.data?.data);
          setFlag(false);
          window.scrollTo({ top: 0 });
        }
        else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [
      pageIndex,
      skip,
      orderStatus,
      userName,
      chatNumber,
      userMobNum,
      astroName,
      astroMobNum,
      callStatus,
      callDuration,
      source,startDate,endDate
    ]
  );

  return (
    <div className="App">
      <div className="gita_quote_heading">
        <div className="container">
          <div className="overflow-x-auto">
            <div className="d-flex counter-wrapper align-items-center justify-content-between">
              <h4 className="hd-list-f font_20 left-q counter-top">
                Total Inquiries <small>{allDatapaidCount}/{allDataCount}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                Today Free Chat <small>{countData?.userfreechat}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Chat <small>{countData?.usersAddedPaidToday}/{countData?.usersAddedToday}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Week Chat <small>{countData?.usersAddedPaidThisWeek}/{countData?.usersAddedThisWeek}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Month Chat <small>{countData?.usersAddedPaidThisMonth}/{countData?.usersAddedThisMonth}</small>
              </h4>
              {/* <div class="_btn"></div> */}
            </div>
          </div>

        </div>
      </div>

      {/* <Container className="mb-2 "> */}
        <div class="chat_window">
              <Row className="mlist my-3 br-6 p-20">
                <div class="chat_search_box user-search">
                  <div className="chat-search-field">
                      <Row>
                       <Form>
                        <Form.Group as={Row} className="mb-2 align-items-end" controlId="">
                          <Col lg={3} md={6} className="mb-3">
                            <Form.Label class="label-f-w-cd">
                              Chat Number
                            </Form.Label>

                            <Form.Control
                              className="mt-2 form-ch"
                              name="chat number"
                              type="text"
                              placeholder="Search Chat No"
                              value={chatNumber}
                              onChange={(e) => {
                                setChatNumber(e.target.value);
                                setSkip(0);
                              }}
                            />
                          </Col>

                          <Col lg={3} md={6} className="mb-3">
                            <Form.Label class="label-f-w-cd">
                              User Name
                            </Form.Label>

                            <Form.Control
                              className="mt-2 form-ch"
                              name="name"
                              type="text"
                              placeholder="Search By Name"
                              value={userName}
                              onChange={(e) => {
                                setUserName(e.target.value);
                                setSkip(0);
                              }}
                            />
                          </Col>

                          <Col lg={3} md={6} className="mb-3">
                            <Form.Label class="label-f-w-cd">
                              User Mobile Number
                            </Form.Label>

                            <Form.Control
                              className="mt-2 form-ch"
                              name="name"
                              type="text"
                              placeholder="Search Mobile Number"
                              value={userMobNum}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                          
                                // Regular expression to allow only numbers (no decimal point, no letters)
                                if (/^\d*$/.test(inputValue)) {
                                  setUserMobNum(inputValue); // Only update the state if the input is a valid number
                                  setSkip(0);
                                }
                              }}
                              maxLength={10}
                            />
                          </Col>

                          <Col lg={3} md={6} className="mb-3">
                            <Form.Label class="label-f-w-cd">
                              Astrologer Name
                            </Form.Label>

                            <Form.Control
                              className="mt-2 form-ch"
                              name="name"
                              type="text"
                              placeholder="Search By Name"
                              value={astroName}
                              onChange={(e) => {
                                setAstroName(e.target.value);
                                setSkip(0);
                              }}
                            />
                          </Col>

                          <Col lg={3} md={6} className="mb-3">
                            <Form.Label class="label-f-w-cd">
                              Astrologer Mobile Number
                            </Form.Label>

                            <Form.Control
                              className="mt-2 form-ch"
                              name="name"
                              type="number"
                              placeholder="Search By Astrologer Mobile Number"
                              value={astroMobNum}
                              onChange={(e) => {
                                setAstroMobNum(e.target.value);
                                setSkip(0);
                              }}
                            />
                          </Col>

                          <Col lg={3} md={6} className="mb-3">
                            <Form.Label class="label-f-w-cd">
                              By Chat Status
                            </Form.Label>
                            <Form.Select
                              aria-label="label-selected-menu"
                              className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                              value={orderStatus}
                              onChange={(e) => {
                                setOrderStatus(e.target.value);
                                setSkip(0);
                              }}
                            >
                              <option value="all">All</option>
                              <option value="5">Failed</option>
                              <option value="1">Requested</option>
                              <option value="2">Accepted</option>
                              <option value="3">In-Progress</option>
                              <option value="4">Complete</option>
                              <option value="6">Cancelled</option>
                              <option value="7">Rejected</option>
                            </Form.Select>
                          </Col>

                          <Col lg={3} md={6} className="mb-3">
                            <Form.Label class="label-f-w-cd">
                              Chat(free/paid)
                            </Form.Label>
                            <Form.Select
                              aria-label="label-selected-menu"
                              className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                              value={callStatus}
                              onChange={(e) => {
                                setCallStatus(e.target.value);
                                setSkip(0);
                              }}
                            >
                              <option value="all"> All</option>
                              <option value="free">Free</option>
                              <option value="paid">Paid</option>
                            </Form.Select>
                          </Col>

                          <Col lg={3} md={6} className="mb-3">
                            <Form.Label class="label-f-w-cd">
                              Duration
                            </Form.Label>
                            <Form.Select
                              aria-label="label-selected-menu"
                              className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                              value={callDuration}
                              onChange={(e) => {
                                setCallDuration(e.target.value);
                                setSkip(0);
                              }}
                            >
                              <option value="all"> Select</option>
                              <option value="1-1">1 min</option>
                              <option value="2-2">2 min</option>
                              <option value="3-3">3 min</option>
                              <option value="3-5">3-5 min</option>
                              <option value="5-10">5-10 min</option>
                              <option value="10-20">10-20 min</option>
                              <option value="20-100000">20+ min</option>
                            </Form.Select>
                          </Col>
                          <Col lg={3} md={6} className="mb-3 mb-lg-0">
                            <Form.Label class="label-f-w-cd">
                              Source
                            </Form.Label>
                            <Form.Select
                              aria-label="label-selected-menu"
                              className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                              value={source}
                              onChange={(e) => {
                                setSource(e.target.value);
                                setSkip(0);
                              }}
                            >
                              <option value="all"> All</option>
                              <option value="web">Web</option>
                              <option value="app">App</option>
                            </Form.Select>
                          </Col>
                          <Col  md={6}  lg={6} xl={4} xxl={3} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                              Select By Chat Date
                            </Form.Label>
                          <div className="date-range-wrapper mt-2">  
                            <div className='select-icon-wrapper'> 
                                <select
                                className="select-range"
                                    value={selectedRange}
                                    onChange={(e) => handleDateRange(e.target.value)}
                                  >
                                      <option value="">Select Date Range</option>
                                      <option value="today">Today</option>
                                      <option value="yesterday">Yesterday</option>
                                      <option value="thisWeek">This Week</option>
                                      <option value="last7Days">Last 7 Days</option>
                                      <option value="thisMonth">This Month</option>
                                      <option value="lastMonth">Last Month</option>
                                      <option value="customDate">Custom Date</option>
                                </select>
                                <img src='/images/down-arrow.png' className='select-icons'/>
                            </div>

                                {selectedRange === "customDate" && (
                                  <DatePicker
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={new Date()}
                                    onChange={(update) => {
                                      const [start, end] = update;
                                      setStartDate(start);
                                      setEndDate(end);
                                    }}
                                    // isClearable={true}
                                    placeholderText="Select custom date range"
                                    dateFormat="yyyy-MM-dd"
                                  />
                                )}
                            </div>
                           </Col>
                          {/* <Col lg={3} md={6} className="mb-3 mb-lg-0">
                            <Form.Label class="label-f-w-cd">
                              Start Date
                            </Form.Label>
                            <DatePicker
                              placeholderText={"Select a starting date"}
                              className="form-control form-ch fil-date-pick mt-2"
                              name="startdate"
                              selected={
                                inputState.startdate
                                  ? new Date(inputState.startdate)
                                  : null
                              }
                              onChange={(date) => {
                                handleStartDate(date);
                                setSkip(0);
                              }}
                              dateFormat="yyyy-MM-dd"
                            />
                          </Col>
                          <Col lg={3} md={6} className="mb-3 mb-lg-0">
                            <Form.Label class="label-f-w-cd">
                              End Date
                            </Form.Label>
                            <DatePicker
                              placeholderText={"Select a ending date"}
                              className="form-control form-ch fil-date-pick mt-2"
                              name="startdate"
                              selected={
                                inputStateP.endDate
                                  ? new Date(inputStateP.endDate)
                                  : null
                              }
                              onChange={(date) => {
                                handleEndDate(date);
                                setSkip(0);
                              }}
                              dateFormat="yyyy-MM-dd"
                            />
                          </Col> */}

                          <Col className="mt-0">
                            <Button
                              variant="secondary"
                              className="as_btn ms-0"
                              onClick={() => {
                                handleSearch();
                                setSkip(0);
                              }}
                            >
                              Filter
                            </Button>
                            <Button
                              variant="secondary"
                              className="as_btn reset-btn"
                              onClick={() => {
                                resetData();
                              }}
                            >
                              Reset
                            </Button>
                          </Col>
                        </Form.Group>
                       </Form>
                      </Row>
                  </div>
                </div>
               
               </Row>
                      {tableData && tableData.length > 0 ? (
                        <DynamicDataTableWithCount
                          columns={columns}
                          data={tableData}
                          totalCount={pageCount}
                          initialState={{
                            pageIndex: pageIndex,
                            pageSize: limit,
                          }}
                          // notShowPage ={true}
                          fetchData={getAllData}
                          forcePage={skip / limit}
                        />
                  ) : (
                    <h2 className="no_records">Records not Available</h2>
                  )}
              {/* <BootstrapTable 
            // keyField='index' 
            // data={users}
             columns={columns} /> */}
           
        </div>
      {/* </Container> */}
      {/* </div> */}

     

      {/* user call confirmation */}
      <Modal
        show={showCallCm}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm}
        centered
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to make a call?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e) => handleConfirm()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn reset-btn"
            onClick={handleCloseCallCm}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* for user note */}
      <Modal
        show={show}
        size="md"
        // aria-labelledby='example-modal-sizes-title-sm'
        scrollable={true}
        backdrop="static"
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header className="modal-header-hd" closeButton>
          <Modal.Title> Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="aa d-flex">
            <span className="me-3">
              {" "}
              Name :- {userForNote?.userId
                ? userForNote?.userId[0]?.name
                : ""}{" "}
            </span>
            <span>
              {" "}
              Contact Number :-{" "}
              {userForNote?.userId
                ? userForNote?.userId[0]?.contactNumber
                : ""}{" "}
            </span>
          </div>

          <Row>
            <Col>
              <Form.Group as={Row} className="my-3" controlId="formAartiInput">
                <Col sm="12">
                  <Form.Control
                    as="textarea"
                    name="content"
                    placeholder="Type here..."
                    value={note}
                    style={{ height: 100 }}
                    onChange={(e) => {
                      handleChange();
                      setNote(e.target.value);
                    }}
                  />
                  {error ? (
                    <p className="text-danger">Please enter your note</p>
                  ) : (
                    ""
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer className="py-2 px-0">
            {loader ? (
              <Button variant="primary" className="as_btn reset-btn">
                Processing...
              </Button>
            ) : (
              <Button
                variant="primary"
                className="as_btn"
                onClick={handleNotesAdd}
              >
                Add
              </Button>
            )}
            <Button
              variant="secondary"
              className="as_btn reset-btn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>

          <h6 className="aa mt-2"> Notes History :-</h6>

          {notesData && notesData?.notes?.length > 0 ? (
            <>
              {notesData?.notes && notesData?.notes?.length>0 &&notesData?.notes?.map((item, index) => (
                <>
                <Row>
                         {item?.source && item?.source != "" ?  
                              <Col xs={5}>
                                  <span className="aa">Source :- </span>
                                    <span className="item-msg">{item.source ? item.source : "" } </span>
                              </Col> : ""
                          }
{item?.source && item?.source !== "" && (
    (item.source === "Leads-Chat-Orders" || item.source === "Leads-Call-Orders") ? (
        item?.sourcename && item.sourcename !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chat-Orders") ? (
                    <span className="aa">Chat Id :- </span>
                ) : (
                    <span className="aa">Call Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Chadhawa-List" || 
         item.source === "Leads-Manokamna-List" || 
         item.source === "Marketing-Chadhawa-List" ||
         item.source === "Marketing-Manokamna-List") ? (
        item?.sourceid && item.sourceid !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chadhawa-List" || item.source === "Marketing-Chadhawa-List") ? (
                    <span className="aa">Chadawa Id :- </span>
                ) : (
                    <span className="aa">Manokmana Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourceid?.length <= 50 ? item?.sourceid : `${item?.sourceid?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Puja-Booking" || 
         item.source === "Leads-Puja-Suggestion" ||
         item.source === "Marketing-Puja-Booking" ||
         item.source === "Marketing-Puja-Feedback" ||
         item.source === "Marketing-Puja-Suggestion") ? (
        item?.sourcename && item.sourcename !== "" && item?.sourceid && (
            <Col xs={7}>
                <span className="aa">Puja Name :- </span>
                <span className="item-msg">
                        {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ):
    item.source === 'Marketing-Reports-Inquiry' ?
    <Col xs={7}>
          <span className='aa'>Reports Name :- </span>
          <span className='item-msg'>
            
              {item?.sourcename?.length <= 50
                ? item?.sourcename
                : `${item?.sourcename?.substring(0, 40)}...`}
          </span>
        </Col> : null
)}
                </Row>
                  <Row className="notes-item">
                    <Col xs={9}>
                    <span className="item-name aa">
                              Note :- 
                      </span>
                      <span className="item-msg">{item?.message}</span>
                    </Col>
                    <Col xs={3}>
                      <div className="text-end d-flex flex-column">
                        <span className="text-primary item-name aa">
                          {item?.responderName || item?.adminName}
                        </span>
                        <span className="item-text">
                          {item?.createdAt
                            ? moment(item?.createdAt).format("D MMM, YYYY h:mm a")
                            : ""}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          ) : (
            <>
              <h4 className="text-center text-secondary">Not Available</h4>
            </>
          )}
        </Modal.Body>
      </Modal>
      {/* astrologer call confirmation */}

      <Modal
        show={showCallCm1}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm1}
        centered
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to make a Astrologer call?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e) => handleConfirm1()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn reset-btn"
            onClick={handleCloseCallCm1}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* show Detail data */}

      <Modal
      className="astrologer_detail-modal"
        show={showSingleDataDetail}
        size="md"
        scrollable={true}
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={()=>setShowSingleDataDetail(false)}
        centered
      >
        <Modal.Header className="modal-header-hd" closeButton>
          <Modal.Title> Chat Detail : {singleData?.chatNumber}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           
        <Container>
            <Row>
              <Col sm={6} className="mb-3">
                 <h4 className="font_20">Astro/Customer Detail</h4>
                 <p> <span className="font-600">Astrologer Name : </span>{singleData?.astrologerId?.[0]?.name}</p>
                 <p> <span className="font-600">Astrologer Number :</span> {singleData?.astrologerId?.[0]?.contactNumber}</p>
                 <p> <span className="font-600">Customer Name : </span>{singleData?.userId?.[0]?.name} </p>
                 <p> <span className="font-600">Customer Number : </span>{singleData?.userId?.[0]?.contactNumber} </p>
                 <p> <span className="font-600">Source :</span> {singleData?.utmSource 
                        ? singleData.utmSource.charAt(0).toUpperCase() + singleData.utmSource.slice(1)
                        : ''}
                  </p>
                 <p> <span className="font-600">Device : </span>{singleData?.deviceType 
                        ? singleData.deviceType.charAt(0).toUpperCase() + singleData.deviceType.slice(1)
                        : ''}
                  </p>
                 <p> <span className="font-600">Created  :</span> {moment(singleData?.createdAt).format("D MMM, YYYY h:mm a")}</p>
                 <p> <span className="font-600">Updated  : </span>{moment(singleData?.updatedAt).format("D MMM, YYYY h:mm a")}</p>
              </Col>
              <Col sm={6} className="mb-0 mb-sm-3">
                 <h4 className="font_20">Chat Detail</h4>

                 <p> <span className="font-600">Status : </span>{singleData?.chatStatus === 1 ? "Requested" :
                    singleData?.chatStatus === 2 ? "Accepted" : singleData?.chatStatus === 3 ? "In-Progress" :
                    singleData?.chatStatus === 4 ? "Completed" : singleData?.chatStatus === 5 ? "Failed" :
                    singleData?.chatStatus === 6 ? "Cancelled" : "Rejected"}
                 </p>
                 <p> <span className="font-600">Total Amount : </span>{singleData?.currencySymbol}{singleData?.totalAmount}.00</p>
                 <p> <span className="font-600">Price Per Minute :</span> {singleData?.currencySymbol}{singleData?.astrologerChatPricePerMinute}.00</p>
                 <p> <span className="font-600">Duration : </span>{singleData?.duration}min</p>
                 <p> <span className="font-600">Astrologer Earning : </span>{singleData?.currencySymbol}
                      {Number.isInteger(singleData?.astrologerEarning) 
                        ? singleData?.astrologerEarning + '.00' 
                        : singleData?.astrologerEarning?.toFixed(2).replace(/\.00$/, '')}
                  </p>
                
                 <p> <span className="font-600">Commission :</span> {singleData?.astrologerChatCommission}%</p>

                 <p><span className="font-600"> Time Limit Duration  :</span> {singleData?.timeLimitDuration} min</p>
                 <p> <span className="font-600">Accepted :</span> {(singleData?.acceptedTime === null || singleData?.acceptedTime === undefined || singleData?.acceptedTime === "") ? "" :  moment(singleData?.acceptedTime).format("D MMM, YYYY h:mm a")} </p>
                 <p> <span className="font-600">Chat Start : </span>{(singleData?.startTime === null || singleData?.startTime === undefined || singleData?.startTime === "") ? "" :  moment(singleData?.startTime).format("D MMM, YYYY h:mm a")}</p>
                 <p> <span className="font-600">Chat End : </span>{(singleData?.endTime === null || singleData?.endTime === undefined || singleData?.endTime === "") ? "" :  moment(singleData?.endTime).format("D MMM, YYYY h:mm a")}</p>
                 <p> <span className="font-600">End By : </span>{ (singleData?.chatStatus === 4 || singleData?.chatStatus === 5 || singleData?.chatStatus === 6) ? singleData?.chatEndBy === 0 ? "Customer" : "System" : ""}</p>
                 {/* <p> End From : {singleData?.endFrom 
                        ? singleData.endFrom.charAt(0).toUpperCase() + singleData.endFrom.slice(1)
                        : ''}</p> */}
                 <p> <span className="font-600">Online Time : </span>{singleData?.actualOnlineTime}min</p>
                 
                 <p> <span className="font-600">Wallet Used : </span>{singleData?.currencySymbol}{singleData?.usedWalletAmount}.00 </p>
                 <p> <span className="font-600">Is Free Chat : </span>{singleData?.isFree === true ? "Yes" : "No"} </p>
                 <p> <span className="font-600">Coin Used: </span>{singleData?.isRewardCoinUsed === true ? "Yes" : "No" }</p>
                 <p> <span className="font-600">Coin Amount : </span>{singleData?.currencySymbol}{singleData?.coinAmount}.00</p>
                 <p> <span className="font-600">Coins : </span>{singleData?.coin}</p>
                 <p> <span className="font-600">Rating Given by Customer : </span>{singleData?.is_rated === true ? "Yes" : "No" }</p>
                 <p> <span className="font-600">Puja Suggested : </span>{singleData?.isPujaSuggested === "yes" ? "Yes" : "No" }</p>
                 <p> <span className="font-600">Astrologer Status : </span>{singleData?.astrologerAction}</p>
                 <p> <span className="font-600">Payout : </span>{singleData?.payStatus 
                        ? singleData.payStatus.charAt(0).toUpperCase() + singleData.payStatus.slice(1)
                        : ''}
                  </p>
                 
              </Col>
            </Row>
            <hr/>
            <Row>
              <h4 className="font_20">Customer Input</h4>
              <p> <span className="font-600">Birth Place : </span>{singleData?.birthPlace}</p>
              <p> <span className="font-600">Birth Time : </span>{moment(singleData?.birthTime).format('DD MMM, **** h:mm a')} </p>
              <p> <span className="font-600">Name : </span>{singleData?.name}</p>
              <p> <span className="font-600">Gender : </span>{singleData?.gender 
                        ? singleData.gender.charAt(0).toUpperCase() + singleData.gender.slice(1)
                        : ''}</p>
              <p> <span className="font-600">Problem : </span>{singleData?.areaOfProblem} </p>
            </Row>
          </Container>
          
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="as_btn reset-btn"
            onClick={()=>setShowSingleDataDetail(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default CallOrders;
