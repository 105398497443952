import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Offcanvas } from "react-bootstrap";
import { PersonCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContextProvider";
import { getCookies, setCookies } from "../../utils/Helper";
import { Link } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  const Email=getCookies("astroResponderEmailleads")
  const { token, refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);


  const handleLogout = (e) => {
    e.preventDefault();
    refreshToken(null);
    setCookies("accessTokenleads", null);
    navigate("/");
  };

  const renderMenuItems = () => {
    return (
      <>
       
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="content_nav">
          <Nav className="ms-auto">
            <NavLink to="/customers" className="nav-link">
              Customers 
            </NavLink>

            <NavLink to="/contactInquiries" className="nav-link">
              Contact Inquiries
            </NavLink>

            <NavLink to="/callOrders" className="nav-link">
              Call Orders
            </NavLink>
            <NavLink to="/chatOrders" className="nav-link">
              Chat Orders
            </NavLink>
            <NavDropdown id="responsive-navbar-nav" title="Puja">
              <NavDropdown.Item as={Link} to="/pujaAllBooking">
                Puja Bookings
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pujaRecommendation">
                Puja Recommendations
              </NavDropdown.Item>
            </NavDropdown>

            <NavLink to="/" className="nav-link">
              Chat
            </NavLink>
            <NavDropdown
              id="responsive-navbar-nav"
              title={
                <span>
                  <PersonCircle className="me-1" size={15} /> {adminUser?.name}
                </span>
              }
              className="main-navbar"
            >
               <NavDropdown.Item onClick={() => navigate("/respondercallhistory")}>
               My Call Histrory
              </NavDropdown.Item>
              <NavDropdown.Item onClick={(e) => handleLogout(e)}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse> */}



      <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} />
      <Navbar.Offcanvas
      className="bg-red"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
        placement="end"
        show={showOffcanvas}
        onHide={handleClose}
      >
          <Offcanvas.Header className="bg-white" closeButton >
            <Offcanvas.Title id="offcanvasNavbarLabel">
               <img src="images/logo_withtagline.png" class="img-fluid sidebar-logo"/>
            </Offcanvas.Title>
          </Offcanvas.Header>
          {
            Email=="gostrategyhubads@gmail.com"?<Offcanvas.Body>
            <Nav className="ms-auto">
              <NavLink to="/pujaAllBooking" className="nav-link" onClick={handleClose}>
                Puja Bookings
              </NavLink>

              <NavDropdown
                // id="offcanvasNavbarDropdown"
                title={
                  <span>
                    <PersonCircle className="me-1" size={15} /> {adminUser?.name}
                  </span>
                }
                className="main-navbar"
              >
                <NavDropdown.Item  className="nav-link dropdown-submenu"  onClick={(e) => { handleLogout(e); handleClose(); }}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Offcanvas.Body>:<Offcanvas.Body>
            <Nav className="ms-auto">
              <NavLink to="/customers" className="nav-link" onClick={handleClose}>
                Customers 
              </NavLink>

              <NavLink to="/astrologerList" className="nav-link" onClick={handleClose}>
                Astrologers
              </NavLink>

              <NavLink to="/contactInquiries" className="nav-link" onClick={handleClose}>
                Contact Inquiries
              </NavLink>

              <NavLink to="/callOrders" className="nav-link" onClick={handleClose}>
                Call Orders
              </NavLink>
              <NavLink to="/chatOrders" className="nav-link" onClick={handleClose}>
                Chat Orders
              </NavLink>

              <NavDropdown id="offcanvasNavbarDropdown" title="Chadhawa">
                   {/* <NavDropdown.Item as={Link} to="/chadhawaList" onClick={handleClose}>
                      Chadhawa Bookings
                    </NavDropdown.Item> */}
                <NavLink  to="/chadhawaList"  className="nav-link dropdown-submenu" onClick={handleClose}>
                   Chadhawa Bookings
                </NavLink>
                <NavLink to="/manokamanaList"  className="nav-link dropdown-submenu" onClick={handleClose}>
                   Manokamana Bookings
                </NavLink>
              </NavDropdown>
              
              <NavDropdown id="offcanvasNavbarDropdown" title="Puja">
                <NavLink to="/pujaAllBooking" className="nav-link dropdown-submenu" onClick={handleClose}>
                  Puja Bookings
                </NavLink>
                <NavLink to="/pujaRecommendation" className="nav-link dropdown-submenu" onClick={handleClose}>
                  Puja Recommendations
                </NavLink>
              </NavDropdown>
              
             

              <NavLink to="/" className="nav-link" onClick={handleClose}>
                Chat
              </NavLink>
              <NavDropdown
                // id="offcanvasNavbarDropdown"
                title={
                  <span>
                    <PersonCircle className="me-1" size={15} /> {adminUser?.name}
                  </span>
                }
                className="main-navbar"
              >
                <NavLink  className="nav-link dropdown-submenu" to="/respondercallhistory" onClick={handleClose}>
                My Call History
                </NavLink>
                <NavDropdown.Item  className="nav-link dropdown-submenu"  onClick={(e) => { handleLogout(e); handleClose(); }}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Offcanvas.Body>
          }
          
      </Navbar.Offcanvas>




      </>
    );
  };

  return (
    <div>
      <div className="row p-0 m-0">
        <div className="col-md-12 p-0 m-0">
          <Navbar expand="xl" className="header navbar-dark pe-2 pe-xl-0">
          <NavLink className="navbar-brand logo-link" to="/">  
            <img src="images/logo_withtagline.png" class="img-fluid main-logo"/>
        </NavLink>
            {token ? (
              renderMenuItems()
            ) : (
              <Nav className="ms-auto">
                <NavLink to="/login" className="nav-link">
                  Login
                </NavLink>
              </Nav>
            )}
          </Navbar>
        </div>
      </div>
    </div>
  );
};

export default Header;
